import { render, staticRenderFns } from "./ProfileSelector.vue?vue&type=template&id=62afae34&"
import script from "./ProfileSelector.vue?vue&type=script&lang=js&"
export * from "./ProfileSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BButton, BCard, BCol, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BAlert, BButton, BCard, BCol, BRow, BContainer})
    

export default component.exports